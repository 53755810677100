import React from 'react';
import styled from 'styled-components';
import { useLayoutQueries } from '../utils/queries';

const StyledSolarPrivacyContainer = styled.div`
  max-width: 800px;
  margin: 0 auto;
`;

const SolarPrivacy = () => {
  const { solarPrivacy: solarData } = useLayoutQueries();

  return (
    <StyledSolarPrivacyContainer
      dangerouslySetInnerHTML={{
        __html: solarData.childPlainText.content,
      }}
    />
  );
};

export default SolarPrivacy;
